<template>
  <b-modal
    id="add-new-plan-sidebar"
    ref="add-new-plan-sidebar"
    :title="$t('Planes.newPlanTitle')"
    :ok-title="t('Planes.buttons.add')"
    :cancel-title="t('Planes.buttons.cancel')"
    :visible="isAddNewPlanSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-add-new-plan-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <!-- BODY -->
      <validation-observer
        #default="{ invalid }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          id="formAdd"
          class="p-2"
          @submit.prevent="addPlan(emit, planData)"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            :name="$t('Planes.planList.planName')"
            rules="required"
          >
            <b-form-group
              :label="$t('Planes.planList.planName')"
              label-for="planName"
            >
              <b-form-input
                id="planName"
                :maxlength="50"
                v-model="planData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                :placeholder="$t('Planes.planList.planNamePlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- per_week -->
          <validation-provider
            #default="validationContext"
            :name="$t('Planes.planList.perWeek')"
            rules="required"
          >
            <b-form-group
              :label="$t('Planes.planList.perWeek')"
              label-for="perWeek"
            >
              <v-select
                v-model="planData.per_week"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perWeekOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="perWeek"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Discipline -->
          <validation-provider
            #default="validationContext"
            :name="$t('Planes.planList.planName')"
            rules="required"
          >
            <b-form-group
              :label="$t('Planes.planList.disciplineName')"
              label-for="disciplineName"
              :state="getValidationState(validationContext)"
              trim
              :placeholder="$t('Planes.planList.disciplineName')"
            >
              <v-select
                v-model="planData.discipline_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="planDisciplinesOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="disciplineName"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- month -->
          <validation-provider
            #default="validationContext"
            :name="$t('Planes.planList.months')"
            rules="required"
          >
            <b-form-group
              :label="$t('Planes.planList.months')"
              label-for="months"
            >
              <v-select
                v-model="planData.months"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="monthsOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="months"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- cost -->
          <validation-provider
            #default="validationContext"
            :name="$t('Planes.planList.cost')"
            rules="required|between:0,999999"
          >
            <b-form-group
              :label="$t('Planes.planList.cost')"
              label-for="cost"
            >
              <b-form-input
                id="cost"
                v-model="planData.cost"
                :state="getValidationState(validationContext)"
                trim
                type="number"
                step="0.01"
                :placeholder="$t('Planes.planList.costPlaceholder')"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-row class="mt-2 mb-1">
            <!-- discount -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Planes.planList.discount')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Planes.planList.discount')"
                  label-for="discount"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="discount"
                    v-model="planData.discount"
                    :options="planYesNoOptions"
                    value=""
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- accumulative -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Planes.planList.accumulative')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Planes.planList.accumulative')"
                  label-for="accumulative"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="accumulative"
                    v-model="planData.accumulative"
                    :options="planYesNoOptions"
                    value=""
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- free_attendance -->
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="validationContext"
                :name="$t('Planes.planList.freeAttendance')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Planes.planList.freeAttendance')"
                  label-for="freeAttendance"
                  label-class="mb-1"
                >
                  <b-form-radio-group
                    id="freeAttendance"
                    v-model="planData.free_attendance"
                    :options="planYesNoOptions"
                    value=""
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <!-- discount_percentage -->
          <div
            v-if="planData.discount == 1"
            class="mb-2"
          >
            <validation-provider
              #default="validationContext"
              :name="$t('Planes.planList.discountPercentage')"
            >
              <b-form-group
                :label="$t('Planes.planList.discountPercentage')"
                label-for="discountPercentage"
              >
                <v-select
                  v-model="planData.discount_percentage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="discountOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="discountPercentage"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
          <!-- submit button -->
          <div class="d-flex flex-wrap float-right mb-2 mt-1">
            <b-button
              variant="secondary"
              class="ml-5"
              data-dismiss="add-new-plan-sidebar"
              :disabled="false"
              @click="hideModal(resetForm)"
            >
              {{ t('Planes.buttons.cancel') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              type="submit"
              :disabled="invalid"
            >
              {{ t('Planes.buttons.add') }}
              <feather-icon
                v-if="loader"
                icon="SettingsIcon"
                size="15"
                class="spinner"
              />
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormRadioGroup, BRow, BCol,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onUnmounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import planStoreModule from '@/views/Plans/planStoreModule'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import planDisciplineStoreModule from '@/views/Plans-disciplines/planDisciplineStoreModule'
import usePlansDisciplineList from '@/views/Plans-disciplines/plans-disciplines-list/usePlansDisciplinesList'
import { loader } from '@core/mixins/ui/globalMixin'

export default {
  components: {
    BCol,
    BRow,
    flatPickr,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [loader],
  model: {
    prop: 'isAddNewPlanSidebarActive',
    event: 'update:is-add-new-plan-sidebar-active',
  },
  props: {
    isAddNewPlanSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  computed: {
    perWeekOptions() {
      const options = []
      for (let i = 1; i <= 7; i++) {
        options.push({ value: i, label: i.toString() })
      }
      return options
    },
    discountOptions() {
      const optionsDisc = []
      for (let j = 5; j <= 50; j += 5) {
        optionsDisc.push({ value: j, label: j.toString() })
      }
      return optionsDisc
    },
  },
  created() {
    this.getPlansDisciplines()
  },
  updated() {
    this.resetForm()
  },
  methods: {
    hideModal(resetForm) {
      resetForm()
      this.$refs['add-new-plan-sidebar'].hide()
    },
  },
  setup(props, { emit }) {
    const blankPlanData = {
      name: '',
      per_week: '',
      discount: 0,
      accumulative: 0,
      free_attendance: 0,
      discount_percentage: '',
      cost: '',
      months: '',
      status: '',
      discipline_id: '',
    }

    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'
    const PLAN_DISCIPLINE_APP_STORE_MODULE_NAME = 'app-plan-discipline'

    // Register module
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    if (!store.hasModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME, planDisciplineStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
      if (store.hasModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_DISCIPLINE_APP_STORE_MODULE_NAME)
    })

    const planData = ref(JSON.parse(JSON.stringify(blankPlanData)))
    const resetPlanData = () => {
      planData.value = JSON.parse(JSON.stringify(blankPlanData))
    }
    const toast = useToast()
    const {
      t,
      addPlan,
      planYesNoOptions,
      monthsOptions,
    } = usePlansList()

    const {
      getPlansDisciplines,
      planDisciplinesOptions,

    } = usePlansDisciplineList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetPlanData)

    return {
      planData,
      addPlan,
      getPlansDisciplines,
      planDisciplinesOptions,
      monthsOptions,
      toast,
      refFormObserver,
      getValidationState,
      planYesNoOptions,
      resetForm,
      emit,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
