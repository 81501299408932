<template xmlns="http://www.w3.org/1999/html">
  <b-modal
    id="delete-plan-sidebar"
    ref="delete-plan-sidebar"
    :title="t('Planes.deletePlan')"
    :visible="isDeletePlanSidebarActive"
    backdrop
    no-header
    hide-footer
    @change="(val) => $emit('update:is-delete-plan-sidebar-active', val)"
  >
    <!--    <template #default="{ hide }">-->
    <template>
      <b-row class="w-100 my-4 ">
        <b-col cols="1" />
        <b-col
          cols="11"
          class=""
        >
          {{ t('Planes.message.delete-plan-text') }}
          ¿ {{ t('Planes.message.delete-plan-questions') }}
          <b-badge
              pill
              variant="light-primary"
          >

            {{ selectedPlan.name }}
          </b-badge> ?
        </b-col>
        <b-col cols="1" />
        <b-col cols="11">

        </b-col>
      </b-row>

      <!-- submit button -->
      <div class="container-fluid  mx-0 my-0 px-0 py-0 mb-2">
        <b-row class="w-100">
          <b-col class="col-5 " />
          <b-col class="col-3 float-right">
            <b-button
              variant="secondary"
              data-dismiss="delete-plan-sidebar"
              :disabled="false"
              @click="hideModal"
            >
              {{ t('Planes.buttons.cancel') }}
            </b-button>
          </b-col>
          <b-col class="col-3 float-right ml-1">
            <b-button
              variant="danger"
              @click="deletePlan(emit, selectedPlan)"
            >
              {{ t('actions.deleteTitle') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BBadge,
  BButton, BCol, BRow,
} from 'bootstrap-vue'

import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import planStoreModule from '@/views/Plans/planStoreModule'

export default {
  components: {
    BBadge,
    BCol,
    BRow,
    BButton,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isDeletePlanSidebarActive',
    event: 'update:is-delete-plan-sidebar-active',
  },
  props: {
    isDeletePlanSidebarActive: {
      type: Boolean,
      required: true,
    },
    selectedPlan: {
      type: Object,
      default: null,
      required: true,
    },
  },
  methods: {
    hideModal() {
      this.$refs['delete-plan-sidebar'].hide()
    },
  },
  setup(props, { emit }) {

    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'
    // Register module
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    const toast = useToast()

    // Cambiar a consumirlo desde la propia clase de membership
    const { t, deletePlan } = usePlansList()
    return {
      emit,
      deletePlan,
      toast,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-membership-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
