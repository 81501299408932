<template>

  <div>
    <plan-list-add-new
      :is-add-new-plan-sidebar-active.sync="isAddNewPlanSidebarActive"
      @refetch-data-plans="refetchDataPlans"
    />

    <plan-delete
      v-if="selectedPlan"
      :is-delete-plan-sidebar-active.sync="isDeletePlanSidebarActive"
      :selected-plan="selectedPlan"
      @refetch-data-plans="refetchDataPlans"
    />
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ t('Clients.clientList.showTitle') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                :maxlength="30"
                class="d-inline-block mr-1"
                :placeholder="$t('Planes.searchPlaceholder')+' ...'"
              />
              <b-button
                variant="primary"
                @click="isAddNewPlanSidebarActive = true"
              >
                <span class="text-nowrap">{{ t('Planes.buttons.addPlan') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPlanListTable"
        class="position-relative"
        :items="fetchPlans"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('emptyText')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Plan name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <template #aside />
            <b-link
              :to="{ name: 'apps-plans-edit', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
          </b-media>
        </template>
        <!-- Column: discount -->
        <template #cell(discount)="data">
          <h6 class="text-muted">
            {{ resolveDiscount(data.item.discount) }}
          </h6>
        </template>
        <!-- Column: discountPercentage -->
        <template #cell(discountPercentage)="data">
          <h6 class="text-muted align-content-center">
            {{ data.item.discount_percentage > 0 ? data.item.discount_percentage +"%" : "-"}}
          </h6>
        </template>
        <!-- Column: cost -->
        <template #cell(cost)="data">
          <h6 class="text-muted">
            {{ (data.item.cost) ? "$" + round(data.item.cost, 2) : "-" }}</h6>
        </template>
        <!-- Column: months -->
        <template #cell(months)="data">
          <h6 class="text-muted">
            {{ resolvePlanPeriod(data.item.months) }}</h6>
        </template>
        <!-- Column: months -->
        <template #cell(discipline)="data">
          <h6 class="text-muted">
            {{ (data.item.discipline.name ) }}</h6>
        </template>
        <!-- Column: created -->
        <template #cell(created)="data">
          <h6 class="text-normal">
            {{ dateLatin(data.item.created) }}
          </h6>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolvePlanStatusVariant(data.item.status.id)}`"
            class="text-capitalize"
          >
            {{ data.item.status.name }}
          </b-badge>
        </template>
        <!-- Column: Actions-->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-plans-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ t('actions.editTitle') }}</span>
            </b-dropdown-item>
            <b-dropdown-item>
              <b-link
                on
                @click="setSelectePlan(data.item)"
              >
                <feather-icon
                    icon="TrashIcon"
                    size="15"
                    :id="`delete-icon-${data.item.id}`"
                />
                <b-tooltip
                  triggers="hover"
                  :target="`delete-icon-${data.item.id}`"
                  :title="t('actions.deleteTitle')"
                  :delay="{ show: 1000, hide: 50 }"
                />
                <span class="align-middle ml-50">{{ t('actions.deleteTitle') }}</span>
              </b-link>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ t('Clients.clientList.dataMeta') }}: {{ dataMeta.to }} - {{ t('total') }}: {{ totalPlans }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPlans"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, dateLatin } from '@core/utils/filter'
import planStoreModule from '@/views/Plans/planStoreModule'
import usePlansList from '@/views/Plans/plans-list/usePlansList'
import UsersListFilters from '@/views/Clients/clients-list/UsersListFilters.vue'
import UserListAddNew from '@/views/Clients/clients-list/UserListAddNew.vue'
import PlanListAddNew from '@/views/Plans/plans-list/PlanListAddNew.vue'
import PlanDelete from '@/views/Plans/plans-delete/PlanDelete.vue'
import { round } from 'echarts/lib/util/number'

export default {
  components: {
    BTooltip,
    PlanDelete,
    PlanListAddNew,
    UsersListFilters,
    UserListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  methods: {
    round,
    setSelectePlan(item) {
      this.selectedPlan = item
      this.isDeletePlanSidebarActive = true
    },
  },

  setup() {
    const selectedPlan = ref()
    const PLAN_APP_STORE_MODULE_NAME = 'app-plan'

    // Register module
    if (!store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.registerModule(PLAN_APP_STORE_MODULE_NAME, planStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PLAN_APP_STORE_MODULE_NAME)) store.unregisterModule(PLAN_APP_STORE_MODULE_NAME)
    })

    const isAddNewPlanSidebarActive = ref(false)
    const isDeletePlanSidebarActive = ref(false)

    const {
      fetchPlans,
      getDate,
      totalRootClient,
      tableColumns,
      perPage,
      currentPage,
      totalPlans,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPlanListTable,
      resolveDiscount,
      refetchDataPlans,
      resolvePlanStatus,
      resolvePlanRoleVariant,
      resolvePlanStatusVariant,
      resolvePlanPeriod,
      // Extra Filters
      t,
    } = usePlansList()
    return {
      // Sidebar
      isAddNewPlanSidebarActive,
      isDeletePlanSidebarActive,
      selectedPlan,
      fetchPlans,
      tableColumns,
      perPage,
      currentPage,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      avatarText,
      dateLatin,
      getDate,
      totalRootClient,
      totalPlans,
      refPlanListTable,
      resolveDiscount,
      refetchDataPlans,
      resolvePlanStatus,
      resolvePlanRoleVariant,
      resolvePlanStatusVariant,
      resolvePlanPeriod,
      t,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
